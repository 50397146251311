<template>
  <div class="lg:w-2/6 md:w-1/2 bg-white shadow-lg rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
    <h3 class='mb-6 text-purple-900 h3'>
      Reset password
    </h3>
    <form  @submit.prevent="signIn">
      <!-- Error message -->
      <div
        v-if="errorMsg"
        class=" text-red-500"
      >
        <span class="text-sm">{{ errorMsg }}</span>
      </div>
      <!-- Input email -->
      <div class="relative mb-4">
        <input 
          v-model.trim="email"
          type="email"
          class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-lg outline-none  text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
          placeholder="Email" />
      </div>
      <!-- Submit button -->
      <button 
        type="submit"
        class="w-full text-white border-0 py-2 px-8 focus:outline-none font-medium  rounded text-xl bg-blue-600"
      >Submit</button>
    </form>
    <div class="text-grey-dark mt-6">
      Already have an account? 
      <router-link 
        to="/signin"
        class="no-underline border-b border-blue text-blue"
      >Log in</router-link>.
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/firebase-app'

export default {
  name: 'ResetView',
  data() {
    return {
      email: null,
      errorMsg: null,
      success: false
    }
  },
  methods: {
    async reset() {
      this.errorMsg = null
      let result = await firebaseApp.resetPassword(this.email)
      if(result.message) {
        this.errorMsg = result.message
      } else {
        this.success = true
      }
    }
  }
}
</script>